import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import BotsIcon from '../../assets/images/product/blurb-icons/bots.png'
import CollaborationIcon from '../../assets/images/product/blurb-icons/collaboration.png'
import OnTopIcon from '../../assets/images/product/blurb-icons/on-top.png'
import SlideAndDiceIcon from '../../assets/images/product/blurb-icons/slide-and-dice.png'
import TakeActionIcon from '../../assets/images/product/blurb-icons/take-action.png'
import TrendsIcon from '../../assets/images/product/blurb-icons/trends.png'
import MetaImage from '../../assets/images/product/insights/insights-meta.png'
import tag from '../../assets/images/tags/insights-tag.svg'
import Layout from '../../components/Layout'
import InsightsCarousel from '../../components/ProductCarousel/InsightsCarousel'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import FooterBlock from '../../components/blocks/FooterBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import ToolsBlock from '../../components/blocks/ToolsBlock'
import Block from '../../components/primitives/Block'
import Blurb from '../../components/system/Blurb'
import Br from '../../components/system/Br'
import GlobalStyleReset from '../../components/system/GlobalStyleReset'
import { responsiveScale } from '../../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/insights/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    engineeringTeams: file(
      relativePath: { eq: "images/product/insights/pr-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1322)
      }
    }
    organizationalInsights: file(
      relativePath: { eq: "images/product/insights/org-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1247)
      }
    }
    workingAgreement1: file(
      relativePath: { eq: "images/product/overview/target.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    workingAgreement2: file(
      relativePath: { eq: "images/product/overview/slack-notification.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    workingAgreement3: file(
      relativePath: { eq: "images/product/overview/working-agreement.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
  }
`

const InsightsPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Insights"
      variant="dark"
      isNew
      description="Swarmia surfaces research-backed engineering metrics, including DORA and SPACE, and insights that guide software teams to take concrete action."
      metaImage={MetaImage}
    >
      <GlobalStyleReset>
        <HeroBlock
          tag={tag}
          title={
            <>
              Measure the right things at every&nbsp;level of the organization
            </>
          }
          content="Improve engineering productivity and developer experience by tracking a balanced set of research-backed software development metrics."
          backgroundImage={getImage(data.backgroundImage)!}
        />
        <LeadBlock
          heading="Get a complete picture of engineering productivity"
          content="Use Swarmia to measure DORA metrics, SPACE framework, and beyond — with carefully curated insights."
        />
        <Block
          paddingTop={responsiveScale(64)}
          paddingBottom={responsiveScale(64)}
          maxWidth={1220}
        >
          <InsightsCarousel />
        </Block>
        <LeadBlock
          title="Swarmia for engineering leaders"
          heading="The insights you need to make better decisions"
          content="Explore healthy software development metrics to recognize concrete areas of improvement for the whole organization or a group of teams."
          image={getImage(data.organizationalInsights)}
        />

        <ColumnsBlock
          paddingTop={responsiveScale(32)}
          paddingBottom={responsiveScale(64)}
          columns={[
            <Blurb
              key="1"
              title="Identify trends and signals"
              text="Quickly see which teams need your support and guidance."
              icon={TrendsIcon}
            />,
            <Blurb
              key="2"
              title="Slice and dice the data"
              text="Drill into metrics behind the aggregates to understand what’s really going on."
              icon={SlideAndDiceIcon}
            />,
            <Blurb
              key="3"
              title="Take action"
              text="Use data to start conversations that drive the teams to improve."
              icon={TakeActionIcon}
            />,
          ]}
        />
        <LeadBlock
          title="Swarmia for engineering teams"
          heading={
            <>
              Empower teams to
              <Br /> do their best work
            </>
          }
          content="Use real-time data to identify and remove blockers that are getting in the way of your team."
          image={getImage(data.engineeringTeams)}
        />
        <ColumnsBlock
          paddingBottom={responsiveScale(64)}
          columns={[
            <Blurb
              key="1"
              title="Improve collaboration"
              text="See all your team’s open pull requests in one place — regardless of the repository."
              icon={CollaborationIcon}
            />,
            <Blurb
              key="2"
              title="Stay on top of stale & risky code"
              text="Clean up old and stale pull requests. Never let PRs fall through the cracks again."
              icon={OnTopIcon}
            />,
            <Blurb
              key="3"
              title="Better ways to work with bots"
              text="Manage bot-created pull requests separately from other work."
              icon={BotsIcon}
            />,
          ]}
        />
        <LeadBlock
          heading="Metrics alone don’t drive change"
          content="Adopt working agreements to stay accountable for things that are important to your team."
          link={{
            title: 'Read more about working agreements',
            href: '/product/working-agreements/',
          }}
        />
        <ColumnsBlock
          paddingTop={responsiveScale(48)}
          paddingBottom={responsiveScale(64)}
          columns={[
            <Blurb
              key="1"
              title="Adopt proven ways of working"
              text="Work with your team to set relevant and achievable targets."
              image={getImage(data.workingAgreement1)}
            />,
            <Blurb
              key="2"
              title="Automate feedback loops"
              text="Get real-time alerts in Slack to keep up with your agreements."
              image={getImage(data.workingAgreement2)}
            />,
            <Blurb
              key="3"
              title="Track progress"
              text="Avoid slipping and celebrate progress with frequent recaps."
              image={getImage(data.workingAgreement3)}
            />,
          ]}
        />
        <ToolsBlock />

        <QuoteBlock person="alex" />
        <FooterBlock
          productPages={['investmentBalance', 'workingAgreements']}
        />
      </GlobalStyleReset>
    </Layout>
  )
}

export default InsightsPage
