import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import ProductCarousel from './ProductCarousel'

const InsightsCarousel = () => {
  const images = useStaticQuery(graphql`
    query {
      flow: file(relativePath: { eq: "images/carousel-images/flow.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 868)
        }
      }

      impact: file(relativePath: { eq: "images/carousel-images/impact1.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 868)
        }
      }

      quality: file(
        relativePath: { eq: "images/carousel-images/quality.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 868)
        }
      }

      collaboration: file(
        relativePath: { eq: "images/carousel-images/collaboration.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 868)
        }
      }

      health: file(relativePath: { eq: "images/carousel-images/health.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 868)
        }
      }
    }
  `)

  return (
    <ProductCarousel
      items={[
        {
          id: 'flow',
          title: 'Flow',
          description:
            'Are we continuously delivering on our priorities without interruptions?',
          image: getImage(images.flow),
        },
        {
          id: 'impact',
          title: 'Impact',
          description: 'Are we driving business outcomes?',
          image: getImage(images.impact),
        },
        {
          id: 'quality',
          title: 'Quality',
          description:
            'Are we delivering high-quality features to our customers?',
          image: getImage(images.quality),
        },
        {
          id: 'collaboration',
          title: 'Collaboration',
          description:
            'Are we effectively collaborating within our team and with other teams?',
          image: getImage(images.collaboration),
        },
        {
          id: 'health',
          title: 'Health',
          description: 'Are we able to maintain balanced ways of working?',
          image: getImage(images.health),
        },
      ]}
    />
  )
}

export default InsightsCarousel
